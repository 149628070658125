/**
 * @name: 热门赛事接口
 * @author: itmobai
 * @date: 2023-06-02 09:26
 * @description：热门赛事接口
 * @update: 2023-06-02 09:26
 */
import {get, postJ} from "@/request";
import {IMarketingMatch} from "@/apis/marketingManage/match/types";

/**
 * 推荐热门赛事
 */
export const recommendContestQueryRecommendContestListApi = () => get<IMarketingMatch[]>("/golf/recommendContest/queryRecommendContestList")
/**
 * 推荐热门赛事修改
 * @param data
 */
export const recommendContestModifyApi = (data: Partial<IMarketingMatch>) => postJ("/golf/recommendContest/modify", data)
/**
 * 热门赛事批量删除
 * @param ids
 */
export const recommendContestBatchDeleteApi = (ids: string) => get("/golf/recommendContest/batchDelete", {ids})
/**
 * 商品上下架
 * @param id
 * @param status
 */
export const recommendContestUpAndDownShelfApi = (id: string, status: number) => postJ("/golf/recommendContest/upAndDownShelf", {id,status})
/**
 * 创建推荐赛事
 * @param data
 */
export const recommendContestCreateApi = (data: any) => postJ("/golf/recommendContest/create", data)
